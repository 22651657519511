import { Icon } from 'components/Icons';
import { NavLink } from 'react-router-dom';
import React from 'react'


export default function Menu() {



    return (
        <div id='tummenu'>
            <nav className="md:px-2">

                <ul className="flex flex-col">
                    <li>

                        <NavLink activeClassName="!text-white " to={"/"} exact className="h-10 flex items-center text-sm font-bold text-link hover:text-white px-4 gap-4 transition-colors">
                            {window.location.href === '/' ? <Icon name="acthome" /> : <Icon name="home" />}
                            <p className='hidden md:block'>Home</p>
                        </NavLink>
                    </li>
                    <li>
                        <a activeClassName="!text-white" to={"/"} className="h-10 flex items-center text-sm font-bold text-link hover:text-white px-4 gap-4 transition-colors">
                            {window.location.href === '/' ? <Icon name="actsearch" /> : <Icon name="search" />}
                            <p className='hidden md:block'>Search</p>
                        </a>
                    </li>
                    <li>
                        <NavLink activeClassName="!text-white" to={"/"} className="h-10 flex items-center text-sm font-bold text-link hover:text-white px-4 gap-4 transition-colors">
                            {window.location.href === '/' ? <Icon name="actcollection" /> : <Icon name="lib" />}
                            <p className='hidden md:block'>Meowbrary</p>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    )
}


