import { Switch, Route } from 'react-router-dom';
import Navbar from 'components/Navbar'

import Home from "views/Home"

export default function content() {
  return(
    <div className='flex-auto overflow-auto'>
        <Navbar />
        <div className='px-4 sm:px-8 pt-5'>
        <Switch>
          <Route exact path="/">
              <Home />
          </Route>
        </Switch>
        </div>
    </div>
  )
}
