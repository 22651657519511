import React from 'react';
import PlayBox from 'components/PlayBox';
import ArtistBox from 'components/ArtistBox';

import { NavLink } from 'react-router-dom';
import { Icon } from 'components/Icons';
import Song from 'components/Song';

const Item = ({ src, text }) => (
  <div className='flex items-center gap-x-2 lg:gap-x-4 bg-dortbox group relative hover:bg-dortboxact rounded'>
    <img className='w-[5rem] h-[5rem]' src={src} />
    <h4 className='hidden md:block text-base font-bold lg:p-4'> {text} </h4>
    {/* <button className='w-12 h-12 bg-primary absolute right-2 transition-opacity rounded-full flex items-center shadow-2xl justify-center opacity-0 group-hover:opacity-100 group-active:opacity-100'>
      <Icon name="play" />
    </button> */}
  </div>
)

export default function Home() {


  const items = [
    {
      id: 7,
      title: 'Super Meow',
      artist: 'Meow Jeans',
      image: '/supershy.webp',
      src: '/music/music11.mp3'
    },
    {
      id: 1,
      title: 'Type shit',
      artist: 'Meowboi Carti',
      image: '/t1.png',
      src: '/music/music1.mp3'
    },

    {
      id: 2,
      title: 'Catnip Spirit',
      artist: 'Purrvana',
      image: '/t5.png',
      src: '/music/music2.mp3'
    },
    {
      id: 3,
      title: '20 minutes',
      artist: 'Lil Meowzi Vert',
      image: '/t9.png',
      src: '/music/music3.mp3'
    },
    {
      id: 4,
      title: 'Animals',
      artist: 'Martin Meowrrix',
      image: '/t7.png',
      src: '/music/music4.mp3'
    },

    {
      id: 5,
      title: 'DDU-DU DDU-DU',
      artist: 'Meowpink',
      image: '/bp.png',
      src: '/music/music5.mp3'
    },
    {
      id: 6,
      title: 'Cupid',
      artist: 'Fifty Fifty',
      image: '/cupid.png',
      src: '/music/music6.mp3'
    },

    {
      id: 8,
      title: 'Unstoppable',
      artist: 'SIA',
      image: '/unstoppable.png',
      src: '/music/music8.mp3'
    },
    {
      id: 9,
      title: 'Barbie Girl',
      artist: 'A Cat',
      image: '/barbie.png',
      src: '/music/music9.mp3'
    },
    {
      id: 10,
      title: 'Cat Pon You',
      artist: ' Doja Catnip',
      image: '/rave.png',
      src: '/music/music10.mp3'
    },
  ]

  const singer = [
    {
      id: 1,
      position: 'Cat wif hat',
      name: 'Top 1',
      image: '/a1.png'
    },
    {
      id: 2,
      position: 'Cat Invasion',
      name: 'Top 2',
      image: '/a2.png'
    },
    {
      id: 3,
      position: 'Meowcalipsis',
      name: 'Top 3',
      image: '/a3.png'
    },
    {
      id: 4,
      position: 'Meownicorn',
      name: 'Top 4',
      image: '/a4.png'
    },
    {
      id: 5,
      position: 'The cats',
      name: 'Top 5',
      image: '/a5.png'
    }
  ]

  const mixer = [

    {
      id: 1,
      position: 'Sonny Fodera, SOMMA, Jay Pryor ve daha fazlası',
      name: 'Daily Mix 1',
      image: '/mix2.png'
    },
    {
      id: 2,
      position: 'SICKO MODE, Cookie, Lean On',
      name: 'Daily Mix 2',
      image: '/mix5.png'
    },

    {
      id: 3,
      position: 'SHEESH,  Meowlene, Enough',
      name: 'Daily Mix 3',
      image: '/mix3.png'
    },
    {
      id: 4,
      position: 'California Catz, Moves like Meowgger, Pumped up Cats',
      name: 'Daily Mix 4',
      image: '/mix4.png'
    },
    {
      id: 5,
      position: 'New Rulez, FE!N, New Semester',
      name: 'Daily Mix 5',
      image: '/mix1.png'
    },

  ]


  return (
    <div>
      <div>
        <h3 className='text-3xl text-white font-bold tracking-tight mb-6'>Trending</h3>
        <div className='grid grid-cols-3 items-center justify-center gap-x-6 gap-y-4 mb-6 transition-all'>
          <Item src='/newjeans.jpg' text='Meow Jeans'></Item>
          <Item src='/michael.png' text='Meowchael Jackson'></Item>
          <Item src='/twenty.jpg' text='Twenty Cat Pilots'></Item>
          <Item src='/nirvana.png' text='Purrvana'></Item>
          <Item src='/travis.jpg' text='Travis Scatt'></Item>
          <Item src='/illit.jpeg' text='Meowllit'></Item>
        </div>
      </div>
      <div className='flex justify-between items-end mb-4'>
        <PlayBox title={'Meowsic'} />
        <PlayBox see />
      </div>
      <div className='music-container'>
        {items.map(item => <Song item={item} key={item.id} />)}
      </div>
      <div className='flex justify-between items-end mb-4 mt-6'>
        <PlayBox title={'Meowly Mix'} />
        <PlayBox see />
      </div>
      <div className='music-container'>
        {mixer.map(item => (
          <NavLink key={item.id} to="/" className={'bg-footer rounded p-4 transition-all hover:bg-menubg group'}>
            <div className='relative '>
              <img className='w-auto h-auto inset-0 object-cover mb-4' src={item.image} />
              <button className='w-12 h-12 bg-primary absolute right-2 bottom-2 transition-opacity rounded-full flex items-center shadow-2xl justify-center opacity-0 group-hover:opacity-100 group-active:opacity-100'>
                <Icon name="play" />
              </button>
            </div>
            <span className='music-text whitespace-nowrap'>{item.name}</span> <br />
            <span className='mt-1 text-[14px] text-link'>{item.position}</span>

          </NavLink>
        ))}
      </div>
      <div className='flex justify-between items-end mb-4 mt-6'>
        <PlayBox title={'Meowrtists'} />
        <PlayBox see />
      </div>
      <div className='music-container'>
        {singer.map(item => (
          <NavLink key={item.id} to="/" className={'bg-footer rounded p-4 transition-all hover:bg-menubg group'}>
            <div className='relative '>
              <img className='w-auto h-auto inset-0 object-cover mb-4 rounded-full' src={item.image} />
              {/* <button className='w-12 h-12 bg-primary absolute right-2 bottom-2 transition-opacity rounded-full flex items-center shadow-2xl justify-center opacity-0 group-hover:opacity-100 group-active:opacity-100'>
                <Icon name="play" />
              </button> */}
            </div>
            <span className='music-text whitespace-nowrap'>{item.name}</span> <br />
            <span className='mt-1 text-[14px] text-link'>{item.position}</span>

          </NavLink>
        ))}
      </div>

    </div>
  )
}