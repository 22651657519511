import React from "react";

const Artist = ({ text, src }) => (
  <li className="mb-2">
    <a
      href="#"
      className="flex items-center gap-2 text-link hover:text-white text-sm font-semibold"
    >
      <img src={src} className="grayscale hover:grayscale-0 w-10 h-10" ></img>
      <p className="hidden md:block">{text}</p>
    </a>
  </li>
)

export default function Playlist() {
  return (
    <nav className="flex">
      <ul>
        <Artist text='Dua Catnipa' src='/dua.png'></Artist>
        <Artist text='Metallica' src='/metallica.png'></Artist>
        <Artist text='Travist Scatt' src='/travis.jpg'></Artist>
        <Artist text='Purrvana' src='/nirvana.png'></Artist>
        <Artist text='Meow jeans' src='/newjeans.jpg'></Artist>
        <Artist text='Twenty Cat Pilots' src='/twenty.jpg'></Artist>
        <Artist text='Meowllit' src='/illit.jpeg'></Artist>
        <Artist text='Freddie Meowrcury' src='/freddie.png'></Artist>
        <Artist text='Meowchael Jackson' src='/michael.png'></Artist>
      </ul>
    </nav>
  );
}
