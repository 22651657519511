import React from 'react';
import { Icon } from 'components/Icons';
import { useHistory } from 'react-router-dom'
import { FaTelegram, FaTwitter } from 'react-icons/fa'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";


export default function Navigation() {

  const history = useHistory()

  return (
    <div className='flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-0'>
      <div className='flex items-center'>
        <a href='#' target='_blank' className='p-[6px] text-2xl rounded-full items-center mr-3'>
          <FaTelegramPlane></FaTelegramPlane>
        </a>
        <a href='#' target='_blank' onClick={() => history.goForward()} className='p-[6px] text-2xl rounded-full items-center mr-2'>
          <FaXTwitter></FaXTwitter>
        </a>
        <a href='#' target='_blank' onClick={() => history.goForward()} className='p-[6px] text-2xl rounded-full items-center mr-2'>
          <img src='./iconAve.png' className='w-[35px]' />
        </a>
      </div>
      <p className='text-xs sm:text-base font-bold text-red-500 animate-pulse'>Contract address soon...</p>
    </div>
  )
}
